import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';
import { defaultEnvironment } from "../../environments/environment.defaults";
import { Settings } from '../core/models/settingsModel.model';
import { UserModel } from '../core/models/userModel';
import { SettingsService } from '../core/services/settings.service';
import { UnitSystemsService } from '../core/services/unitsystems.service';
import { UnitSystem } from '../shared/models/uom-models';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class SettingsComponent implements OnInit, OnDestroy {
  public uomModalType: string = 'user';
  public pageLoaded: boolean = false;
  public user: UserModel;
  public settings: Settings;
  public notificationSettingsData;
  public jobs: any;
  public gmt: any;
  public isSaveDisabled: boolean = true;
  public IsThemeDark: boolean = true;
  public pageInit: string = '';
  public selectedUnitSystem: UnitSystem;
  public selectedUnitSystemId: string;
  public selectedUnitSystemId2: string;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public uomModal: NgxSmartModalComponent;

  constructor(
    public _settings: SettingsService,
    public _unitSystems: UnitSystemsService,
    public _ngxSmartModal: NgxSmartModalService,
    public location: Location,
  ) {
  }

  ngOnInit() {
    this.user = this._settings._utilities.currentUser;
    this.settings = this.user.settings;
    this.IsThemeDark = (this._settings.settings.activeTheme === "dark");
    this.pageLoaded = true;
    this.initSettings();
    // this._settings._utilities.userModelObj$.pipe(takeUntil(this.componentDestroyed$)).subscribe(user => {
    //   if (user && user.settings) {
    //     this.selectedUnitSystemId = user.settings.activeUnitSystemId;
    //     this.selectedUnitSystemId2 = this.selectedUnitSystemId;
    //     this._unitSystems.setSelectedUnitSystem(this.selectedUnitSystemId);
    //     this.pageInit = this.initSettings();
    //   }
    // });
  }

  // initialize page after modal closes
  ngAfterViewInit() {
    this._ngxSmartModal.getModal('uomModal').onClose.subscribe((modal: NgxSmartModalComponent) => {
      this.initSettings();
    });
  }

  ngOnDestroy() {
    if(this.uomModal){
      this.uomModal.close();
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public get isLocal() {
    return environment.local;
  }

  cancelSettings(): void {
    this.location.back();
  }

  saveSettings() {
    this._settings.postUserSettings(this._settings.settings);
    this.initSettings();
  }

  initSettings() {
    this.selectedUnitSystemId = this.settings.activeUnitSystemId;
    this.selectedUnitSystemId2 = this.selectedUnitSystemId;
    this._unitSystems.setSelectedUnitSystem(this.selectedUnitSystemId);
    this.pageInit = this._settings.selectedTheme + "_" + this._settings.selectedTimeZone + "_" + this.selectedUnitSystemId;
  }

  onSettingsUpdate(evt, jobItem) {
    switch (jobItem) {
      case 'theme':
        this._settings.selectedTheme = this.IsThemeDark ? defaultEnvironment.darkTheme : defaultEnvironment.lightTheme;
        this._settings.settings.activeTheme = this._settings.selectedTheme;
        this._settings.setTheTheme(this._settings.selectedTheme);
        break;
      case 'timezone':
        this._settings.settings.activeTimezone = this._settings.selectedTimeZone;
        break;
      case 'unitSystem':
        let showCustomCreate = (this.selectedUnitSystemId === "_create");
        if (showCustomCreate) {
          this.uomModalType = "create";
          this.openUnitSystemsModal();
          this.uomModalType = "user";
          setTimeout(() => {
            this.selectedUnitSystemId = this._unitSystems.selectedUnitSystem.unitSystemId;
          }, 0);
          return;
        }
        if (this.selectedUnitSystemId2 != this.selectedUnitSystemId) {
          this.selectedUnitSystemId2 = this.selectedUnitSystemId;
          this._unitSystems.setGlobalUnitSystem(this.selectedUnitSystemId);
          this._unitSystems.selectedUnitSystem = this._unitSystems.globalUnitSystem;
          //this._settings.settings.activeUnitSystem = this._unitSystems.selectedUnitSystem.name;
          this._settings.settings.activeUnitSystemId = this._unitSystems.selectedUnitSystem.unitSystemId;
          //if (this._unitSystems._utilities.jobId$.value === '') {
            this._unitSystems.setCurrentUnitSystem('');
          //}
        }
        break;
    }
    this.saveSettings();
    this.isSaveDisabled = true;
  }

  openUnitSystemsModal() {
    this._ngxSmartModal.setModalData({ uomModalType: this.uomModalType }, "uomModal", true);
    this.uomModal = this._ngxSmartModal.getModal("uomModal");
    this.uomModal.open();
  }

}
