import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../core/services/api.service';
import { Config } from '../core/services/config';
import { JobDetails, SalesDetails } from '../shared/models/jobs.model';
import * as LS from 'src/app/utils/localstorage';
import { DataLogicService } from '../core/services/data-logic.service';
import { SettingsService } from '../core/services/settings.service';
import { createObjIfNotExist } from 'src/app/utils/objecter';
import { API_RESPONSE, CUSTOMER_TYPE, DATE_TIME_FORMAT, TOASTER_TYPE } from '../shared/enums/enums';
import * as OB from "src/app/utils/objecter";
import { DosStatus, Integration, JobState, JobViewStatus, PATHS, SAVE_DATA, VARIABLES } from '../utils/constants';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { map, switchMap } from 'rxjs/operators';
import { DateTimeService } from '../core/services/date-time.services';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  public jobId$ = new BehaviorSubject<string>('');
  public wellID$ = new BehaviorSubject<string>('');
  public jobObj$ = new BehaviorSubject<JobDetails>(new JobDetails());
  public salesObj$ = new BehaviorSubject<SalesDetails>(new SalesDetails());
  public jobName$ = new BehaviorSubject<string>('');
  public rigName$ = new BehaviorSubject<string>('');
  public operator$ = new BehaviorSubject<string>('');
  public activity$ = new BehaviorSubject<string[]>([]);
  public wellName$ = new BehaviorSubject<string>('');
  public jobView$ = new BehaviorSubject<JobViewStatus>(JobViewStatus.Initial);
  public jobViewActual$ = new BehaviorSubject<JobViewStatus>(JobViewStatus.Initial);
  public currentJobId: string;
  public jobDetailsStringInit: string;
  public jobDetailsSaveError: boolean = false;
  public jobDetailsError: boolean = false;
  public jobDetailsErrors: Array<string> = [];
  public jobDetailsIsChanged: boolean = false;
  public salesDetailsStringInit: string;
  public salesDetailsSaveError: boolean = false;
  public salesDetailsError: boolean = false;
  public salesDetailsErrors: Array<string> = [];
  public salesDetailsIsChanged: boolean = false;

  constructor(
    public api: ApiService,
    public dataLogic: DataLogicService,
    public _settings: SettingsService,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService
    ) {
  }

  public setOperator() {
    const jobCustomerType = this.jobObj$.value[VARIABLES.jobCustomerType];
    const jobId = this.jobObj$.value[VARIABLES.jobId];
    const rigUrl = Config.APIUrlCore + environment.rigs.get.replace("{jobId}", jobId);
    const wellUrl = Config.APIUrlCore + environment.wellHeader.get.replace("{jobId}", jobId);
    const path = jobCustomerType == CUSTOMER_TYPE.CONTRACTOR ? PATHS.rig.owner : PATHS.wellHeader.operator;
    const url = jobCustomerType == CUSTOMER_TYPE.CONTRACTOR ? rigUrl : wellUrl;
    const cacheKey = jobCustomerType == CUSTOMER_TYPE.CONTRACTOR ? LS.LOCAL_STORAGE_KEY.Data.Rig.Rig : LS.LOCAL_STORAGE_KEY.Data.Well.WellHeader;

    const cache = LS.getLocalStorage(cacheKey);
    if(cache) {
      this.operator$.next(OB.getObj(cache, path));
      return;
    }

    this.api.get<any[]>(url).subscribe(
      (res) => { this.operator$.next(OB.getObj(res, path)) },
      (error) => { this.operator$.next(jobCustomerType) }
    );
  }

  public authenticator(jobId: string, userId: string){
      let url = Config.APIUrlCore + environment.jobs.authenticator.replace("{jobId}", jobId).replace("{userId}", userId);
      return new Observable(ob => {
        this.api.get<any[]>(url).subscribe(
          (res) => {
            ob.next(res);
            ob.complete();
          },
          (error) => {
            ob.next({allowAccess :true,message : ""});
            ob.complete();
          }
        );
      });
  }

  public allowCurrentUserAccess(job) {
    if (job?.isTightHole) {
      let usersAccess = job?.tightHoleUsers || [];
      if(job?.jobOwner) usersAccess.push(job.jobOwner);

      if (usersAccess.length > 0) {
        let currentUser = this._settings.settings.userId;
        return usersAccess.includes(currentUser);
      }
    }
    return true;
  }

  public searchJobs(params: Object) {
    let url = Config.APIUrlCore + environment.jobs.searchJobs;
    return new Observable<any>(ob => {
      this.api.post<any>(url, params).subscribe(
        (res) => {
          ob.next(res);
          ob.complete();
        },
        (error) => {
          ob.next([]);
          ob.complete();
        }
      );
    });
  }

  public setJobId(jobId: string) {
    this.jobId$.next(jobId)
  }

  public setJobObj(data: JobDetails) {
    if (data && data.jobId) {
      this.jobDetailsStringInit = JSON.stringify(data);
      this.jobObj$.next(data);
      this.jobId$.next(data.jobId);
      this.jobName$.next(data.jobName);
      this.activity$.next(data.jobTypes);
      this.rigName$.next(data.rigName);
      this.setJobReadOnly(data);
      this.setOperator();
    }
  }

  private setJobReadOnly(job) {
    this.jobView$.next(JobViewStatus.Editable);
    this.jobViewActual$.next(JobViewStatus.Editable);

    if(job && job[VARIABLES.jobState] == JobState.Planning) {
      if(job[VARIABLES.dosStatus] == DosStatus.Approved 
          || job[VARIABLES.importedBy] == Integration.TechSight) {
        this.jobView$.next(JobViewStatus.Readonly);
        let msg = '';
        if (job[VARIABLES.importedBy] == Integration.TechSight) {
          msg = this._utilities.getTranslationNow('warnings.notEditableTSImported');
        }
        if (job[VARIABLES.dosStatus] == DosStatus.Approved) {
          msg = this._utilities.getTranslationNow('warnings.notEditableDosApproved');
        }
        this._utilities.showToastrWithTimer(TOASTER_TYPE.WARNING, msg, SAVE_DATA.Toastr_Fail);
      }
    } 
    
    if(job && job[VARIABLES.jobState] == JobState.Execution) {
      this.jobViewActual$.next(JobViewStatus.Readonly);
    }
  }

  public getById(jobId): Observable<any> {
    var url = Config.APIUrlCore + environment.jobs.getById.replace("{jobId}", jobId);
    return this.api.get(url);
  }

  public getDetails(jobId: string) {
    var url = Config.APIUrlCore + environment.jobs.getById.replace("{jobId}", jobId);
    return new Observable(ob => {
      var cached = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.Data.Job.JobDetails);
      if (cached) {
        ob.next(cached);
        ob.complete();
      }
      this.api.get<any[]>(url).subscribe(
        (res) => {
          ob.next(res);
          ob.complete();
        },
        (error) => {
          ob.next(null);
          ob.complete();
        }
      );
    });
  }

  public setSaleObj(data: SalesDetails) {
    if (data && data.jobId) {
      this.salesDetailsStringInit = JSON.stringify(data);
      this.salesObj$.next(data);
    }
  }

  // get sales data, check localstorage then db, else create
  public getSalesDetails(jobId_) {
    var key = LS.LOCAL_STORAGE_KEY.Data.Job.SalesDetails;
    var data = LS.getLocalStorage(key);
    if (data) {
      this.setSaleObj(data);
    }
    else {
      var url = Config.APIUrlCore + environment.sales.getById.replace("{jobId}", jobId_);
      return this.api.get<SalesDetails>(url).subscribe(
        (data) => {
          LS.setLocalStorage(key, data);
          this.setSaleObj(data);
        },
        (err) => {
          let data: SalesDetails = {
            "jobId": jobId_,
            "salesInformationId": UUID.UUID(),
            "salesOfficeAddress": "",
            "salesOfficePhone": "",
            "warehouseAddress": "",
            "warehousePhone": ""
          }

          var url = Config.APIUrlCore + environment.sales.post;
          this.api.post<SalesDetails>(url, data).subscribe(response => {
            LS.setLocalStorage(key, data);
            this.setSaleObj(data);
          });
        },
        () => {
        }

      );

    }
  }

  async postSalesDetails(salesDetails: any) {
    //console.log("saveSalesInfoUpdate=", salesDetails)
    var url = Config.APIUrlCore + environment.sales.post;
    return await this.api.post<SalesDetails>(url, salesDetails).toPromise()
      .then(resp => {
        this.setSaleObj(salesDetails);
        this.salesDetailsStringInit = JSON.stringify(salesDetails);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        this.salesDetailsSaveError = true;
        return err;
      });
  }

  async postJobDetails(jobDetails: any) {
    //console.log("saveJobInfoUpdate=", jobDetails)
    var url = Config.APIUrlCore + environment.jobs.post;
    return await this.api.post<JobDetails>(url, jobDetails).toPromise()
      .then(resp => {
        this.setJobObj(jobDetails);
        this.jobDetailsStringInit = JSON.stringify(jobDetails);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        this.jobDetailsSaveError = true;
        return err;
      });
  }

  public async getJobPacket(jobId) {
    var url = Config.APIUrlCore + environment.jobs.packet.replace("{jobId}", jobId);
    return await this.api.get<any>(url).toPromise()
  }

  public async getJobsAsync() {
    var url = Config.APIUrlCore + environment.jobs.get;
    return await this.api.get<any[]>(url).toPromise()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return [];
      });
  }

  public searchSortPage(payload) {
    var url = Config.APIUrlCore + environment.jobs.searchSortPage;

    return this.api.post<any[]>(url, payload).pipe(map((data: any) => {
      for(let job of data.results) {
        if(job.spudDate) {
          job.spudDate = this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG);
        }
      }
      
      return data;
    }));;
  }

  public async getJobsCloudAsync() {
    var url = Config.APIUrlCore + environment.jobs.getCloud;
    return await this.api.get<any[]>(url).toPromise().then(
      (resp) => {
        return resp;
      }).catch((err) => {
        return [];
    });
  }

  public async getJobsByIds(jobIds: Array<string>) {
    var url = Config.APIUrlCore + environment.jobs.getByJobIds;
    return await this.api.post(url, jobIds).toPromise().then(
      (resp) => {
        return resp;
      }).catch((err) => {
        return [];
    });
  }

  public async transferJobAsync(payload) {
    return await this.dataLogic.postAsync(Config.APIUrlCore + environment.jobs.transferJob, payload);
  }

  public async createOrUpdateJobAsync(payload) {
    createObjIfNotExist(payload, 'jobOwner', this._settings.settings.userId);
    return await this.dataLogic.postAsync(Config.APIUrlCore + environment.jobs.post, payload);
  }

  public async createDefaultJob(id) {
    let data = new JobDetails();
    data.jobId = id;
    data.jobName = "Job Name";
    data.jobTypes = ["Completion Fluids"];
    data.jobCustomerType = "Contractor";
    data.jobPurpose = "Actual";
    data.jobStatus = "Active";
    data.rigName = "";
    data.wellName = "";
    data.spudDate = "";
    return await this.createOrUpdateJobAsync(data);
  }

  public async cloneJob(payload) {
    if (!environment.local) {
      payload.jobState = JobState.Planning
    };
    return await this.api.post(Config.APIUrlCore + environment.jobs.clone, payload).toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  }

  
  public getLastVisitedJobs(): Observable<any> {
    const key = LS.LOCAL_STORAGE_KEY.User.RecentJobs;
    const userId = this._utilities.currentUser.userId;
    const jobIdUrl = Config.APIUrlCore + environment.user.getLastVisitedJob.replace("{userId}", userId);

    return this.dataLogic.get(jobIdUrl, key).pipe(switchMap(ids => {
      const jobUrl = Config.APIUrlCore + environment.jobs.getByJobIds;
      return this.api.post(jobUrl, ids).pipe(map(jobs => {
        for(let job of jobs) {
          if(job.spudDate) {
            job.spudDate = this._dateTime.utcToTimezone(job.spudDate, job.timezone, DATE_TIME_FORMAT.LONG);
          }
        }
        
        const result = {
          results: jobs,
          totalCount: jobs.length
        }
        return result;
      }));
    }));
  }
}
