<div class="container job-padding mt-0">
  <div class="row mx-0 mb-4 pt-5">
    <div class="h1 page-name">
      <span>{{'job.findExistingJobs' | translate}}</span>
    </div>
  </div>
  <div class="container p-0">
    <div class="row">
      <div class="search-job col-md-4">
        <div class="h4 refine-text mt-3">{{'refineby'|translate}}</div>
        <div>
          <div class="default comp-label">{{'job.name'|translate}}</div>
          <input class="input-field w-100 two-col-search" [(ngModel)] = "params.jobName"  type="text">
        </div>
        <div>
          <div class="default comp-label">{{'well.name'|translate}}</div>
          <input class="input-field w-100 two-col-search" [(ngModel)] = "params.wellName"  type="text">
        </div>
        <div class="search-btn my-5">
          <button [ngClass]="inputValid? 'bttn bttn-primary':'bttn bttn-primary-inactive'"
            [disabled]="inputValid? null: true" (click)="searchJobs()" type="button"
            class="w-100 ml-0 two-col-search">{{'search'|translate}}</button>
        </div>
      </div>
  
      <div class="col-md-8 griddata">
        <div class="h4 results-spacing text-capitalize mb-0 mt-4">
          <span *ngIf="!searched">{{'Results' | translate}}</span>
          <span *ngIf="searched && jobs.length == 0">{{jobs.length}}&nbsp;{{'Results' | translate}}</span>
          <span *ngIf="searched && jobs.length == 1">{{jobs.length}}&nbsp;{{'Result' | translate}}</span>
          <span *ngIf="searched && jobs.length > 1">{{jobs.length}}&nbsp;{{'Results' | translate}}</span>
        </div>
        <div *ngIf="!searched" class="default filter-spacing">
          {{'filter.SearchJobs'|translate}}
        </div>
        <div *ngIf="searched && jobs.length == 0" class="default filter-spacing">
          {{'filter.SearchNoResults'|translate}}
        </div>
  
        <!-- result table -->
        <ng-container *ngIf="searched && jobs.length > 0">
          <app-h-table (row)="onSelectJob($event)" [fullTable]="true" [tableName]="'myTable'" [hidden]="false"
            [enterCustom]="false" [download]="false" [data]="jobs" [headers]="headers" [rowPointer]="false"
            [pageSize]="10">
          </app-h-table>
        </ng-container>
      </div>
    </div>
    <div class="row pull-right mt-4">
      <button (click)="cancel()" value="{{'buttons.cancel'|translate}}"
        class="bttn bttn-secondary">{{'buttons.cancel'|translate}}</button>
    </div>
  </div>
</div>

<ngx-smart-modal 
#cloneJob [identifier]="'cloneJob'" 
[dismissable]="false" 
customClass="modal-content modal-custom no-padding display-modal" 
style="width: 100%;">
    <div class="modal-header bottom-grey-border">
      <div class="h2">{{'copyJob'|translate}} </div>
      <div class="close-btn">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <img class="closeIcon" />
        </button>
      </div>
    </div>
    <div class="modal-body">
      <form [formGroup]="cloneJobForm">
        <div class="px-3 py-0 container">
          <div class="row my-4">
            <div class="col-md-3 display-label">
              <label>{{'jobToCopy'|translate}}</label>
            </div>
            <div class="col-md-9 px-0">
              <input value="{{selectedJob?.jobName}}" readonly="true" class="input-field" type="text">
            </div>
          </div>

          <div class="row" [ngClass]="cloneJobForm.controls.jobId.invalid && isCloneFormSubmitted ? 'mb-2' : 'mb-4'">
            <div class="col-md-3 display-label">
              <label>{{'Job Packet'|translate}}</label>
            </div>
            <div class="col-md-9 px-0">
              <select class="select-dropdown" formControlName="jobId" [ngClass]="{'input-danger': cloneJobForm.controls.jobId.invalid && isCloneFormSubmitted}">
                <option *ngFor="let job of selectedJob?.jobs" [value]="job.jobId">{{job.jobState}}</option>
              </select>
              <app-error-message [controlName]="cloneJobForm.controls.jobId" [submitted]="isCloneFormSubmitted"></app-error-message>
            </div>
          </div>
    
          <div class="row" [ngClass]="cloneJobForm.controls.jobName.invalid && isCloneFormSubmitted ? 'mb-2' : 'mb-4'">
            <div class="col-md-3 display-label">
              <label>{{'newJobName'|translate}}</label>
            </div>
            <div class="col-md-9 px-0">
              <input formControlName="jobName" class="input-field" type="text" 
              [maxlength]="limitName"
              [ngClass]="{'input-danger': cloneJobForm.controls.jobName.invalid && isCloneFormSubmitted}" />
              <app-error-message [controlName]="cloneJobForm.controls.jobName" [submitted]="isCloneFormSubmitted"></app-error-message>
            </div>
          </div>
    
          <div class="row" [ngClass]="cloneJobForm.controls.wellName.invalid && isCloneFormSubmitted ? 'mb-2' : 'mb-4'">
            <div class="col-md-3 display-label">
              <label>{{'newWellName'|translate}}</label>
            </div>
            <div class="col-md-9 px-0">
              <input formControlName="wellName" class="input-field" type="text"
              [maxlength]="limitName"
              [ngClass]="{'input-danger': cloneJobForm.controls.wellName.invalid && isCloneFormSubmitted}" />
              <app-error-message [controlName]="cloneJobForm.controls.wellName" [submitted]="isCloneFormSubmitted"></app-error-message>
            </div>
          </div>
    
          <div class="row" [ngClass]="cloneJobForm.controls.dTimSpud.invalid && isCloneFormSubmitted ? 'mb-2' : 'mb-4'">
            <div class="col-md-3 display-label">
              <label>{{'newSpudDate'|translate}}</label>
            </div>
            <div class="col-md-9 px-0">
              <input formControlName="dTimSpud" class="input-field" type="date" 
              max='9999-12-30'
              [ngClass]="{'input-danger': cloneJobForm.controls.dTimSpud.invalid && isCloneFormSubmitted}" />
              <div *ngIf="cloneJobForm.controls.dTimSpud.invalid && isCloneFormSubmitted">
                <p *ngIf="cloneJobForm.controls.dTimSpud.errors.required" class="small error-text">
                  {{'enter.validdate' | translate}}
                </p>
                <p *ngIf="cloneJobForm.controls.dTimSpud.errors.limitDate" class="small error-text">
                  {{'errors.limitDate' | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>  
    </div>
    <div class="modal-footer top-grey-border">
      <button class="bttn bttn-secondary mx-1" (click)="closeModal()">{{'buttons.cancel'|translate}}</button>
      <button class="bttn bttn-primary mx-1" (click)="submit()">{{'buttons.copy'|translate}}</button>
    </div>
</ngx-smart-modal>













