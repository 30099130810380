import { CHARACTERS } from "../shared/enums/enums";

export const PREVENT_PLUS_MINUS_KEYE = [CHARACTERS.PLUS, CHARACTERS.MINUS, CHARACTERS.KeyE];

export const PREVENT_DOT = [CHARACTERS.DOT];

export enum JobState {
  Planning = 'Planning',
  Execution = 'Execution'
}

export enum DosStatus {
  Approved = 'Approved',
  NotApproved = 'NotApproved'
}

export enum Integration {
  WellSight = 'WellSight',
  TechSight = 'TechSight'
}

export enum BaralogixTheme {
  Dark = 'dark',
  Light = 'light'
}

export enum WellStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Complete = 'Complete',
  Planning = 'Planning',
  Abandoned = 'Abandoned'
}

export enum JobStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Complete = 'Complete'
}

export enum UNIT_TYPE_QUANTITY {
  ACCELERATION = 'Acceleration',
  ANGLE = 'Angle',
  AREA = 'Area',
  CAPACITY = 'Capacity',
  COMPRESSIBILITY = 'Compressibility',
  CONCENTRATION = 'Concentration',
  DENSITY = 'Density',
  DEPTH = 'Depth',
  DIAMETER = 'Diameter',
  DOGLEG = 'Dogleg',
  FORCE = 'Force',
  FORCE_PER_LENGTH = 'ForcePerLength',
  FREQUENCY = 'Frequency',
  LENGTH = 'Length',
  MAGNETIC_FLUX_DENSITY = 'MagneticFluxDensity',
  PERCENT = 'Percent',
  POWER = 'Power',
  PRESSURE = 'Pressure',
  PRESSURE_DROPPER_LENGTH = 'PressureDropPerLength',
  RATE = 'Rate',
  RATE_OF_PENETRATION = 'RateOfPenetration',
  RESISTANCE = 'Resistance',
  RESISTIVITY = 'Resistivity',
  ROTARY_SPEED = 'RotarySpeed',
  SHEAR_RATE = 'ShearRate',
  SHEAR_STRESS = 'ShearStress',
  SPECIFIC_HEAT_CAPACITY = 'SpecificHeatCapacity',
  STROKE_RATE = 'StrokeRate',
  TAU0 = 'Tau0',
  TEMPERATURE = 'Temperature',
  THERMAL_CONDUCTIVITY = 'ThermalConductivity',
  THERMAL_DIFFUSIVITY = 'ThermalDiffusivity',
  THERMAL_EXPANSION = 'ThermalExpansion',
  TIME = 'Time',
  TORQUE = 'Torque',
  VELOCITY = 'Velocity',
  VISCOSITY = 'Viscosity',
  VOLUME = 'Volume',
  VOLUME_PER_STROKE = 'VolumePerStroke',
  WEIGHT = 'Weight',
  WEIGHT_PER_LENGTH = 'WeightPerLength',
};

export enum JobViewStatus {
  Initial = 'Initial',
  Editable = 'Editable',
  Readonly = 'Readonly'
}

export const SAVE_DATA = {
  DeferSave: false,
  BatchModify: false,
  ClearCache: true,
  Toastr_Success: 2000,
  Toastr_Fail: 5000,
}

export const CURRENT_UNITS = {
  Name: "API",
  Loaded: false,
  Loading: null
}

export const API_UNITS = {
  Density: "ppg",
}

export const PROPERTY_TYPE = {
  GENERAL: 'General',
  NAF: 'NAF',
  WBM: 'Water',
  ADDITIONAL: 'Additional'
}

export const RUN_TYPE = {
  DRILLING: 'Drilling',
  CASING_LINER: 'Casing/Liner',
  OTHER: 'Other',
  OPEN_ENDED: 'Open Ended'
};

export const PARAM_MAP = {
  JOB_ID: 'jobId',
  WELLBORE_ID: 'wellboreId',
  INTERVAL_ID: 'intervalId',
  HYDRAULICS_REQUEST_ID: 'hydraulicsRequestId',
  BHA_ID: 'bhaRunId',
  ANALYSIS_ID: 'analysisId',
  ANALYSIS_CASE_ID: 'analysisCaseId'
};

export const DEBOUNCE_TIME_AUTO_SAVE = 300
export const DEFAULT_TARGET_PROPERTY_VALUE = 0

export const UNIT_TYPE = {
  PERCENT: '%'
}

export const DEFAULT_NAF_PROPERTIES = [
  {
    propertyName: 'Electrical Stability',
    uom: 'V'
  },
  {
    propertyName: 'Alkalinity',
    uom: 'mL'
  },
  {
    propertyName: 'WPS',
    uom: 'mg/L',
    uomArray: ['kg/L', 'kg/m^3', 'ppb', 'mg/L', 'g/cc', 'ppm']
  },
  {
    propertyName: 'THTS',
    uom: 'ppb'
  },
  {
    propertyName: 'AgNO3',
    uom: 'mL'
  },
  {
    propertyName: 'CaCl2',
    uom: 'ppb'
  },
  {
    propertyName: 'Sol NaCl',
    uom: 'ppb'
  },
  {
    propertyName: 'Insol NaCl',
    uom: 'ppb'
  },
  {
    propertyName: 'NAF/Water Ratio',
    uom: '%',
    restCol: true,
    percentCol: true
  }
]

export const DEFAULT_WBM_PROPERTIES = [
  {
    propertyName: 'MBT',
    uom: 'mg/L',
    uomArray: ['kg/L', 'kg/m^3', 'ppb', 'mg/L', 'g/cc']
  },
  {
    propertyName: 'pH',
    uom: ''
  },
  {
    propertyName: 'Pm',
    uom: 'mL'
  },
  {
    propertyName: 'Pf',
    uom: 'mL'
  },
  {
    propertyName: 'Mf',
    uom: 'mL'
  },
  {
    propertyName: 'Chlorides',
    uom: 'mg/L',
    uomArray: ['kg/L', 'kg/m^3', 'ppb', 'mg/L', 'g/cc']
  },
  {
    propertyName: 'Total Hardness',
    uom: 'mg/L',
    uomArray: ['kg/L', 'kg/m^3', 'ppb', 'mg/L', 'g/cc']
  }
]

export const DEFAULT_GENERAL_PROPERTIES = [
  {
    propertyName: 'Density',
    uom: 'ppg'
  },
  {
    propertyName: 'Funnel Vis',
    uom: 'sec/qt'
  },
  {
    propertyName: 'Plastic Viscosity',
    uom: 'cp'
  },
  {
    propertyName: 'Yield Point',
    uom: 'lb/100ft^2'
  },
  {
    propertyName: 'Tau0',
    uom: 'lb/100ft^2'
  },
  {
    propertyName: '10 sec Gels',
    uom: 'lb/100ft^2'
  },
  {
    propertyName: '10 min Gels',
    uom: 'lb/100ft^2'
  },
  {
    propertyName: '30 min Gels',
    uom: 'lb/100ft^2'
  },
  {
    propertyName: 'API Filtrate',
    uom: 'mL/30min'
  },
  {
    propertyName: 'HPHT Filtrate',
    uom: 'mL/30min',
    temperature: 0,
    uomTemperature: 'ºF',
    temperatureCol: 'Temperature',
  },
  {
    propertyName: 'NAF Content',
    uom: '%'
  },
  {
    propertyName: 'Water Content',
    uom: '%'
  },
  {
    propertyName: 'Sand Content',
    uom: '%'
  },
  {
    propertyName: 'Lime Content',
    uom: 'ppb'
  },
  {
    propertyName: 'LGS %',
    uom: '%'
  },
  {
    propertyName: 'LGS',
    uom: 'ppb'
  }
]

export enum HYDRAULICS_PROPERTIES {
  AverageSpecificGravity = 'AverageSpecificGravity',
  Brine = 'Brine',
  Density = 'Density',
  Gel10M = 'Gel10M',
  Gel10S = 'Gel10S',
  Gel30M = 'Gel30M',
  kPrime = 'kPrime',
  KPL = 'KPL',
  nPrime = 'nPrime',
  nPrimePL = 'nPrimePL',
  Tau0 = 'Tau0',
  PV = 'PV',
  YP = 'YP',
  OilWaterRatio = 'OilWaterRatio',
  SaltConcentration = 'SaltConcentration',
  Temperature = 'Temperature',
  RheologyBeta = 'RheologyBeta',
  BaraVisIE568 = 'BaraVisIE568',
  _600 = '600',
  _300 = '300',
  _200 = '200',
  _100 = '100',
  _6 = '6',
  _3 = '3',
  ThermalExpansion = 'ThermalExpansion',
  Compressibility = 'Compressibility'
};

export enum HYDRAULICS_FLUID_MAPPING_DATA {
  OilOfOWR = HYDRAULICS_PROPERTIES.OilWaterRatio,
  BrineConcentration = HYDRAULICS_PROPERTIES.SaltConcentration,
  ASGOfSolids = HYDRAULICS_PROPERTIES.AverageSpecificGravity,
  FluidDensity = HYDRAULICS_PROPERTIES.Density,
  DensityTemperature = HYDRAULICS_PROPERTIES.Density,
  BaraVisIE568 = HYDRAULICS_PROPERTIES.BaraVisIE568,
  Gel10sec = HYDRAULICS_PROPERTIES.Gel10S,
  Gel10min = HYDRAULICS_PROPERTIES.Gel10M,
  Gel30min = HYDRAULICS_PROPERTIES.Gel30M,
  nPrime = HYDRAULICS_PROPERTIES.nPrime,
  kPrime = HYDRAULICS_PROPERTIES.kPrime,
  Tau0 = HYDRAULICS_PROPERTIES.Tau0,
  PV = HYDRAULICS_PROPERTIES.PV,
  YP = HYDRAULICS_PROPERTIES.YP,
  nPrimePL = HYDRAULICS_PROPERTIES.nPrimePL,
  KPL = HYDRAULICS_PROPERTIES.KPL,
  ThermalExpansion = HYDRAULICS_PROPERTIES.ThermalExpansion,
  Compressibility = HYDRAULICS_PROPERTIES.Compressibility
};

export enum DOWNHOLE_RHEOLORY_PREDICTION_MODEL {
  NONE = 'None',
  GENERIC = "Generic",
  BARAECD = "BaraECD",
  FANN75 = "Fann 75",
};

export enum DOWNHOLE_PARAMETERS_MODAL {
  hoursPumping = 'hoursPumping',
  thermalGradient = 'thermalGradient',
  timeSinceCirculation = 'timeSinceCirculation'

};

export const DOWNHOLE_RHEOLORY_PREDICTION_MODELS = [
  DOWNHOLE_RHEOLORY_PREDICTION_MODEL.NONE,
  DOWNHOLE_RHEOLORY_PREDICTION_MODEL.GENERIC,
  DOWNHOLE_RHEOLORY_PREDICTION_MODEL.BARAECD,
  DOWNHOLE_RHEOLORY_PREDICTION_MODEL.FANN75,
];

export const RHEOMETER_VISCOSITY_SPEED_VALUES = [600, 300, 200, 100, 6, 3];

export const TYPE_HOLE_CASING = {
  BLOW_OUT_PREVENTER: "blow out preventer",
  CASING: "casing",
  CONDUCTOR: "conductor",
  CURVED_CONDUCTOR: "curved_conductor",
  LINER: "liner",
  OPEN_HOLE: "open_hole",
  RISER: "riser",
  TUBING: "tubing"
}

export const PAGINATION_PROPERTIES = {
  DEFAULT_PAGE: 1,
  START_ITEM: 0,
  MAX_SIZE: 10, //Number, limit number for page links in pager
  ITEMS_PER_PAGE: 10, // number, maximum number of items per page. 
  PREVIOUS_TEXT: "<",
  FIRST_TEXT: "<<",
  NEXT_TEXT: ">",
  LAST_TEXT: ">>",
}

export const PIT_COLUMN_INFO = {
  LENGTH: "length",
  WIDTH: "width",
  HEIGHT: "height",
  DEPTH: "depth",
  SECONDARY_HEIGHT: "secondaryHeight",
  DIAMETER: "diameter",
  SECONDARY_DIAMETER: "secondaryDiameter",
  VOLUME: "volume",
  CAPMX: "capMx"
}

export const PIT_STYLE_OPTION = {
  HORIZONTAL_CYLINDRICAL: "Horizontal Cylindrical",
  HORIZONTAL_CYLINDRICAL_ELLIPTICAL_ENDS: "Horizontal Cylindrical Elliptical Ends",
  HORIZONTAL_CYLINDRICAL_SPHERICAL_ENDS: "Horizontal Cylindrical Spherical Ends",
  RECTANGULAR: "Rectangular",
  VERTICAL_CYLINDRICAL: "Vertical Cylindrical",
  VERTICAL_CYLINDRICAL_CONICAL_BOTTOM: "Vertical Cylindrical Conical Bottom",
  VERTICAL_CYLINDRICAL_ROUND_BOTTOM: "Vertical Cylindrical Round Bottom",
}

export const TUBULAR_TYPE = {
  BIT: "Bit",
  DRILL_PIPE: "Drill Pipe",
  CWT: "CWT",
  BYPASS: "Bypass",
  CROSSOVER: "Crossover"
};

export const ANALYSIS_TYPES = {
  DRILL_AHEAD_HYDRAULICS: 'DrillAhead Hydraulics',
  DRILL_AHEAD_OPTIMIZATION: 'DrillAheadOptimization',
  HYDRAULICS: 'Hydraulics',
  DOWNHOLE: 'Downhole',
  SURGE_SWAB: 'Surge/Swab',
  SWEEP: 'Sweep',
  DISPLACEMENT: 'Displacement',
};

export const ANALYSIS_TYPE_ICONS = [
  {
    name: ANALYSIS_TYPES.DRILL_AHEAD_HYDRAULICS,
    icon: 'drillahead'
  },
  {
    name: ANALYSIS_TYPES.DRILL_AHEAD_OPTIMIZATION,
    icon: 'drillaheadoptimization'
  },
  {
    name: ANALYSIS_TYPES.HYDRAULICS,
    icon: 'hydraulics'
  },
  {
    name: ANALYSIS_TYPES.DOWNHOLE,
    icon: 'downhole'
  },
  {
    name: ANALYSIS_TYPES.SURGE_SWAB,
    icon: 'surge'
  },
  {
    name: ANALYSIS_TYPES.SWEEP,
    icon: 'sweep'
  },
  {
    name: ANALYSIS_TYPES.DISPLACEMENT,
    icon: 'sweep'
  },
];


export const UNUSED_QUANTITY_UNITS = [
  'Acceleration',
  'Capacity',
  'Compressibility',
  'Force',
  'ForcePerLength',
  'Frequency',
  'MagneticFluxDensity',
  'Power',
  'PressureDropPerLength',
  'Resistance',
  'Resistivity',
  'SpecificHeatCapacity',
  'ThermalConductivity',
  'ThermalDiffusivity',
  'ThermalExpansion',
  'Torque',
  'VolumePerStroke',
  'Weight'
]

export const LIMITATION = {
  INPUT_NAME: 200
}

export const TRAJECTORY_CALCULATION_TYPE = {
  Tvd: "tvd",
  Inclination: "inclination"
}

export const WELLBORE_TYPE = {
  Initial: "Initial",
  Redrill: "Redrill",
  Reentry: "Reentry",
  Respud: "Respud",
  Sidetrack: "Sidetrack"
}

export const PIPE_ROTATION_ECCENTRICITY_MODE = {
  concentric: 0,
  generic: 2,
  erd: 1
};

export const VARIABLES = {
  parentWellboreTitle: "parentWellboreTitle",
  parentWellboreId: "parentWellboreId",
  typeWellbore: "typeWellbore",
  wellboreTitle: "wellboreTitle",
  wellboreId: "wellboreId",
  mdKickoff: "mdKickoff",
  tvdKickoff: "tvdKickoff",
  md: "md",
  azi: "azi",
  incl: "incl",
  tvd: "tvd",
  dispNs: "dispNs",
  dispEw: "dispEw",
  vertSect: "vertSect",
  dls: "dls",
  trajectoryStationId: "trajectoryStationId",
  trajectory: "trajectory",
  trajectoryId: "trajectoryId",
  trajectoryWITSML: "trajectoryWITSML",
  calcAlgorithm: "calcAlgorithm",
  tieOn: "tieOn",
  tangential: "tangential",
  sourceStation: "sourceStation",
  wellboreParent: "wellboreParent",
  wellboreGeometry: "wellboreGeometry",
  wellboreGeometryWITSML: "wellboreGeometryWITSML",
  wellboreGeometryId: "wellboreGeometryId",
  value: "value",
  uom: "uom",
  disabled: "disabled",
  wellId: "wellId",
  calculateTvdFromInclination: "calculateTvdFromInclination",
  wellboreGeometrySectionWITSML: "wellboreGeometrySectionWITSML",
  wellboreGeometrySectionId: "wellboreGeometrySectionId",
  sectionMdInterval: "sectionMdInterval",
  mdBase: "mdBase",
  mdTop: "mdTop",
  endMd: "endMd",
  topMd: "topMd",
  startMd: "startMd",
  odSection: "odSection",
  idSection: "idSection",
  wtPerLen: "wtPerLen",
  grade: "grade",
  title: "title",
  riser: "riser",
  selected: "selected",
  surfaceEquipmentWITSML: "surfaceEquipmentWITSML",
  idStandpipe: "idStandpipe",
  lenStandpipe: "lenStandpipe",
  idHose: "idHose",
  lenHose: "lenHose",
  idSwivel: "idSwivel",
  lenSwivel: "lenSwivel",
  idKelly: "idKelly",
  lenKelly: "lenKelly",
  openHoleIds: "openHoleIds",
  baraLogixTypeTubularComponent: "baraLogixTypeTubularComponent",
  baraLogix: "baraLogix",
  tubularComponentWITSML: "tubularComponentWITSML",
  connection: "connection",
  id: "id",
  od: "od",
  len: "len",
  lenJointAv: "lenJointAv",
  bhaRunWITSML: "bhaRunWITSML",
  tubular: "tubular",
  tubularComponents: "tubularComponents",
  bhaRunId: "bhaRunId",
  wellboreGeometrySections: "wellboreGeometrySections",
  typeHoleCasing: "typeHoleCasing",
  intervalId: "intervalId",
  casingLinersRef: "casingLinersRef",
  openHolesRef: "openHolesRef",
  bhaRunsRef: "bhaRunsRef",
  areaNozzleFlow: "areaNozzleFlow",
  nozzle: "nozzle",
  isSelected: "isSelected",
  numStringRun: "numStringRun",
  sequence: "sequence",
  temperature: "temperature",
  fluidSetIds: "fluidSetIds",
  targetSetsRef: "targetSetsRef",
  rig: "rig",
  rigUtilization: "rigUtilization",
  rigUtilizationWITSML: "rigUtilizationWITSML",
  surfaceEquipment: "surfaceEquipment",
  solidsControlEquipment: "solidsControlEquipment",
  mudPumps: "mudPumps",
  pits: "pits",
  well: "well",
  wellWITSML: "wellWITSML",
  intervals: "intervals",
  wellbores: "wellbores",
  bhaRuns: "bhaRuns",
  thermalGradient: "thermalGradient",
  data: "data",
  casingLinerIds: "casingLinerIds",
  bhaRunIds: "bhaRunIds",
  targetSets: "targetSets",
  targetSetNumber: "targetSetNumber",
  latitude: "latitude",
  longitude: "longitude",
  jobCustomerType: "jobCustomerType",
  owner: "owner",
  operator: "operator",
  rigWITSML: "rigWITSML",
  jobId: "jobId",
  numInterval: "numInterval",
  pressure: "pressure",
  ThermalExpansion: "ThermalExpansion",
  Compressibility: "Compressibility",
  jobState: "jobState",
  dosStatus: "dosStatus",
  importedBy: "importedBy"
}

export const KEYS = {
  wellbore: {
    wellboreTitle: VARIABLES.wellboreTitle,
    typeWellbore: VARIABLES.typeWellbore,
    parentWellboreTitle: VARIABLES.parentWellboreTitle,
    tieOnMd: "tieOnMd",
    tieOnTvd: "tieOnTvd",
    mdKickoff: VARIABLES.mdKickoff,
    tvdKickoff: VARIABLES.tvdKickoff,
    md: VARIABLES.md,
    azi: VARIABLES.azi,
    incl: VARIABLES.incl,
    tvd: VARIABLES.tvd
  },
  trajectory: {
    md: VARIABLES.md,
    incl: VARIABLES.incl,
    azi: VARIABLES.azi,
    tvd: VARIABLES.tvd,
    dispNs: VARIABLES.dispNs,
    dispEw: VARIABLES.dispEw,
    vertSect: VARIABLES.vertSect,
    dls: VARIABLES.dls,
    tieOnMd: "tieOnMd",
    parentMaxMd: "parentMaxMd"
  },
  toolbar: {
    action: "toolbarAction",
    allowEdit: "allowEdit",
    allowDelete: "allowDelete",
    showWarning: "showWarning",
    warningMessage: "warningMessage"
  },
  riser: {
    selected: "selected",
    description: "description",
    od: "od",
    id: "id",
    topMd: "topMd",
    endMd: "endMd",
    weight: "weight",
    grade: "grade",
    wellboreId: "wellboreId",
    wellboreGeometryId: "wellboreGeometryId",
    wellboreGeometrySectionId: "wellboreGeometrySectionId"
  },
  formations: {
    tvd: VARIABLES.tvd,
    value: VARIABLES.value,
    pressure: VARIABLES.pressure
    }
}

export const AnalysisSource = {
  user: "User",
  request: "Request"
}

export const PATHS = {
  wellbore: {
    tieOn: VARIABLES.trajectory + "." + VARIABLES.trajectoryWITSML + "." + VARIABLES.tieOn,
    mdKickoff: VARIABLES.mdKickoff + "." + VARIABLES.value,
    tvdKickoff: VARIABLES.tvdKickoff + "." + VARIABLES.value,
    md: VARIABLES.md + "." + VARIABLES.value,
    tvd: VARIABLES.tvd + "." + VARIABLES.value,
    wellboreGeometryId: VARIABLES.wellboreGeometry + "." + VARIABLES.wellboreGeometryWITSML + "." + VARIABLES.wellboreGeometryId,
    wellboreGeometrySections: VARIABLES.wellboreGeometry + "." + VARIABLES.wellboreGeometrySections,
    tieOnMd: VARIABLES.trajectory + "." + VARIABLES.trajectoryWITSML + "." + VARIABLES.tieOn + "." + VARIABLES.md + "." + VARIABLES.value,
    tieOnTvd: VARIABLES.trajectory + "." + VARIABLES.trajectoryWITSML + "." + VARIABLES.tieOn + "." + VARIABLES.tvd + "." + VARIABLES.value,
    trajectoryId: VARIABLES.trajectory + "." + VARIABLES.trajectoryWITSML + "." + VARIABLES.trajectoryId,
    calculateTvdFromInclination: VARIABLES.trajectory + "." + VARIABLES.calculateTvdFromInclination
  },
  trajectory: {
    trajectoryId: VARIABLES.trajectoryWITSML + "." + VARIABLES.trajectoryId,
    tieOn: VARIABLES.trajectoryWITSML + "." + VARIABLES.tieOn,
    tieOnMd: VARIABLES.trajectoryWITSML + "." + VARIABLES.tieOn + "." + VARIABLES.md + "." + VARIABLES.value,
    tieOnTvd: VARIABLES.trajectoryWITSML + "." + VARIABLES.tieOn + "." + VARIABLES.tvd + "." + VARIABLES.value
  },
  trajectoryStation: {
    md: VARIABLES.md + "." + VARIABLES.value,
    incl: VARIABLES.incl + "." + VARIABLES.value,
    azi: VARIABLES.azi + "." + VARIABLES.value,
    tvd: VARIABLES.tvd + "." + VARIABLES.value,
    dispNs: VARIABLES.dispNs + "." + VARIABLES.value,
    dispEw: VARIABLES.dispEw + "." + VARIABLES.value,
    vertSect: VARIABLES.vertSect + "." + VARIABLES.value,
    dls: VARIABLES.dls + "." + VARIABLES.value
  },
  toolbar: {
    allowEdit: KEYS.toolbar.action + "." + KEYS.toolbar.allowEdit,
    allowDelete: KEYS.toolbar.action + "." + KEYS.toolbar.allowDelete,
    showWarning: KEYS.toolbar.action + "." + KEYS.toolbar.showWarning
  },
  geometrySection: {
    title: VARIABLES.title,
    wellboreGeometrySectionId: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.wellboreGeometrySectionId,
    typeHoleCasing: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.typeHoleCasing,
    odSection: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.odSection + "." + VARIABLES.value,
    idSection: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.idSection + "." + VARIABLES.value,
    wtPerLen: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.wtPerLen + "." + VARIABLES.value,
    grade: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.grade,
    mdBase: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.sectionMdInterval + "." + VARIABLES.mdBase + "." + VARIABLES.value,
    mdTop: VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.sectionMdInterval + "." + VARIABLES.mdTop + "." + VARIABLES.value,
  },
  caseSetup: {
    riser: {
      title: VARIABLES.riser + "." + VARIABLES.title,
      wellboreGeometrySectionId: VARIABLES.riser + "." + VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.wellboreGeometrySectionId,
      odSection: VARIABLES.riser + "." + VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.odSection + "." + VARIABLES.value,
      idSection: VARIABLES.riser + "." + VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.idSection + "." + VARIABLES.value,
      wtPerLen: VARIABLES.riser + "." + VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.wtPerLen + "." + VARIABLES.value,
      mdBase: VARIABLES.riser + "." + VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.sectionMdInterval + "." + VARIABLES.mdBase + "." + VARIABLES.value,
      mdTop: VARIABLES.riser + "." + VARIABLES.wellboreGeometrySectionWITSML + "." + VARIABLES.sectionMdInterval + "." + VARIABLES.mdTop + "." + VARIABLES.value,
    }
  },
  rig: {
    surfaceEquipment: {
      idStandpipe: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.idStandpipe + "." + VARIABLES.value,
      lenStandpipe: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.lenStandpipe + "." + VARIABLES.value,
      idHose: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.idHose + "." + VARIABLES.value,
      lenHose: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.lenHose + "." + VARIABLES.value,
      idSwivel: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.idSwivel + "." + VARIABLES.value,
      lenSwivel: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.lenSwivel + "." + VARIABLES.value,
      idKelly: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.idKelly + "." + VARIABLES.value,
      lenKelly: VARIABLES.surfaceEquipmentWITSML + "." + VARIABLES.lenKelly + "." + VARIABLES.value,
    },
    owner: VARIABLES.rigWITSML + "." + VARIABLES.owner
  },
  tubularComponent: {
    baraLogix: VARIABLES.baraLogixTypeTubularComponent + "." + VARIABLES.baraLogix,
    connectionId: VARIABLES.tubularComponentWITSML + "." + VARIABLES.connection + "." + VARIABLES.id + "." + VARIABLES.value,
    connectionOd: VARIABLES.tubularComponentWITSML + "." + VARIABLES.connection + "." + VARIABLES.od + "." + VARIABLES.value,
    connectionLen: VARIABLES.tubularComponentWITSML + "." + VARIABLES.connection + "." + VARIABLES.len + "." + VARIABLES.value,
    lenJointAv: VARIABLES.tubularComponentWITSML + "." + VARIABLES.lenJointAv + "." + VARIABLES.value,
    id: VARIABLES.tubularComponentWITSML + "." + VARIABLES.id + "." + VARIABLES.value,
    od: VARIABLES.tubularComponentWITSML + "." + VARIABLES.od + "." + VARIABLES.value,
    areaNozzleFlow: VARIABLES.tubularComponentWITSML + "." + VARIABLES.areaNozzleFlow + "." + VARIABLES.value,
    nozzle: VARIABLES.tubularComponentWITSML + "." + VARIABLES.nozzle,
    sequence: VARIABLES.tubularComponentWITSML + "." + VARIABLES.sequence,
  },
  bhaRun: {
    tubularComponents: VARIABLES.bhaRunWITSML + "." + VARIABLES.tubular + "." + VARIABLES.tubularComponents,
    bhaRunId: VARIABLES.bhaRunWITSML + "." + VARIABLES.bhaRunId,
    numStringRun: VARIABLES.bhaRunWITSML + "." + VARIABLES.numStringRun
  },
  jobPacket: {
    surfaceEquipment: VARIABLES.rig + "." + VARIABLES.rigUtilization + "." + VARIABLES.rigUtilizationWITSML + "." + VARIABLES.surfaceEquipment,
    solidsControlEquipment: VARIABLES.rig + "." + VARIABLES.rigUtilization + "." + VARIABLES.rigUtilizationWITSML + "." + VARIABLES.solidsControlEquipment,
    mudPumps: VARIABLES.rig + "." + VARIABLES.rigUtilization + "." + VARIABLES.rigUtilizationWITSML + "." + VARIABLES.mudPumps,
    pits: VARIABLES.rig + "." + VARIABLES.rigUtilization + "." + VARIABLES.rigUtilizationWITSML + "." + VARIABLES.pits,
    intervals: VARIABLES.well + "." + VARIABLES.intervals,
    wellbores: VARIABLES.well + "." + VARIABLES.wellWITSML + "." + VARIABLES.wellbores,
    bhaRuns: VARIABLES.well + "." + VARIABLES.wellWITSML + "." + VARIABLES.bhaRuns,
    thermalGradients: VARIABLES.well + "." + VARIABLES.thermalGradient + "." + VARIABLES.data
  },
  wellHeader: {
    latitude: VARIABLES.wellWITSML + "." + VARIABLES.latitude,
    longitude: VARIABLES.wellWITSML + "." + VARIABLES.longitude,
    operator: VARIABLES.wellWITSML + "." + VARIABLES.operator
  },
  testResults: {
    thermalExpansion: VARIABLES.ThermalExpansion + "." + VARIABLES.pressure + "." + VARIABLES.value, 
    compressibility: VARIABLES.Compressibility + "." + VARIABLES.temperature + "." + VARIABLES.value 
  }
}